<template>
  <octo-table
    :enable-store="false"
    :action="endpoints.DATATABLES.userEventLogAccesses.replace('{id}', $route.params.id)"
    :fields="fields"
    :filters="filters"
    :show-id="false"
    :showPageSelect="false"
    name="UserEventLogDataTable"
    @onResetFilters="resetFilters"
  >
    <template v-slot:browser="data">
      <div>{{ data.row.browser }}</div>
      <div class="small">{{ data.row.platform }}</div>
    </template>

    <template v-slot:device="data">
      <div>{{ data.row.device }} ({{ deviceTypePrefix(data.row.deviceType) }})</div>
      <div class="small">{{ data.row.ipAddress }}</div>
    </template>

    <template v-slot:type="data">
      <div class="text-uppercase text-truncate">
        <label-theme-component>{{ data.row.type }}</label-theme-component>
      </div>
      <div class="small">{{ data.row.date | date }} {{ data.row.time }}</div>
    </template>

  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "UserEventLogDatatable",
  components: {LabelThemeComponent, OctoIcon, OctoTable},
  data() {
    return {
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'type', label: 'type', slot: true, align: 'left'},
        {prop: 'browser', label: 'browser', slot: true, align: 'center'},
        {prop: 'device', label: 'device', slot: true, align: 'center'},

      ]
    }
  },
  methods: {

      resetFilters: function () {
        this.filters = {

        };
      },

    deviceTypePrefix: function (deviceType) {
      switch(deviceType) {
        case 'desktop':
          return 'D';
        case 'mobile':
          return 'M';
        default:
          return 'N.D.';
      }
    }
  }
}
</script>

<style scoped>

</style>
