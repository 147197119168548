<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.firstname')">
      <template slot="value">
        <base-input maxlength="30" class="mb-0 flex-grow-1" v-model="userLocal.firstname" type="text"/>
      </template>
    </list-group-item-component>

    <list-group-item-component :label-width="labelWidth" :label="$t('fields.lastname')">
      <template slot="value">
        <base-input maxlength="30" class="mb-0 flex-grow-1" v-model="userLocal.lastname" type="text"/>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
import User from "@/models/user";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import BaseTextArea from "@/components/Inputs/BaseTextArea";

export default {
  name: "TutorForm",
  data() {
    return {
      labelWidth: 200,
      userLocal: _.cloneDeep(User)
    }
  },
  components: {BaseTextArea, ListGroupItemComponent},
  props: {
    user: {
      type: Object,
      default: () => _.cloneDeep(User)
    }
  },
  mounted() {
    this.userLocal = this.$_.cloneDeep(this.user);
  },
  methods: {
    getTutorData: function () {
      return this.userLocal;
    }
  },
}
</script>

<style scoped>

</style>
