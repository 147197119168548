<template>
  <card body-classes="standard-card-body" class="shadow-sm">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('common.courses') }}</h4>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush">
          <list-group-item-component
            v-for="(course, index) in coursesChunks[0]"
            v-bind:key="`course-1-${index}`"
            :label="course.code"
            :value="course.title"
          />
        </ul>
      </div>
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush">
          <list-group-item-component
            v-for="(course, index) in coursesChunks[1]"
            v-bind:key="`course-1-${index}`"
            :label="course.code"
            :value="course.title"
          />
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center text-capitalize" v-if="courses.length === 0">
        <p>{{ $t('datatable.no_result') }}</p>
      </div>
    </div>
  </card>
</template>

<script>
import User from "@/models/user";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "TutorCourses",
  components: {ListGroupItemComponent},
  props: {
    user: {
      type: Object,
      default: () => this.$_.cloneDeep(User)
    }
  },
  computed: {
    coursesChunks() {
      return this.$_.chunk(this.courses, this.$_.ceil(this.courses.length / 2));
    },

    courses() {
      return this.user?.courses || [];
    }
  }
}
</script>

<style scoped>

</style>
